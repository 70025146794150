/* Typography */
$family-sans-serif: Source Sans Pro, Open Sans, sans-serif !default;
$family-monospace: monospace !default;
$render-mode: optimizeLegibility !default;

/* Typography Sizes and Weights */

$base-font-size: 1rem !default;
$base-line-height: 1.2 !default;
$base-letter-spacing: 0.9px !default;

$size-1: 0.875rem !default; // 14px For <p>,<li>,<h5>,<h6>
$size-2: 0.6875rem !default; // 12px For small message;
$size-3: 1rem !default; //16px For <p> for strong language
$size-4: 1.125rem !default; //18px For <h4>
$size-5: 1.375rem !default; //22px For <h3>
$size-6: 1.75rem !default; //28px For <h2>
$size-7: 2.125rem !default; //34 For <h1>

/* Typography Font Weights */
$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;
$weight-bolder: 900 !default;



/* Color */
// Primary
$primary-red: #ff0000 !default;
$primary-black: #000029 !default;


// Secondary

$secondary-green: #73AF00 !default;
$secondary-dark-gray: #7C8091 !default;
$secondary-light-gray: #f4f6f9 !default;


$background-color: #fff;

/* Z-index */
$z1: 100 !default;
$z2: 200 !default;
$z3: 300 !default;
$z4: 400 !default;

