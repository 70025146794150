.EMICalculator {
  background-color: white;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 12px;
  .Title {
    display: flex;
    align-items: center;
    color: var(--base-color-gray-dark);
    .TitleText {
      display: flex;
      flex-direction: column;
    }
  }
  .PlanForm {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 24px;
    justify-content: space-between;
    .formInputContainer {
      width: 23%;
    }
  }
  .PlanInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .PaymentInfo {
      display: flex;
      flex-direction: column;
    }
  }
  .UpFrontContainer {
    margin-top: 24px;
    .UpFrontPayment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 6px;
      border-bottom: 1px solid rgb(205, 199, 199);
    }
    .BreakdownPayment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 0;
    }
  }
  .Lenders {
    margin-top: 12px;
    padding-top: 24px;
    border-top: 1px solid rgb(205, 199, 199);

    .LenderList {
      display: flex;
      align-items: center;
      justify-content: center;
      .Lender {
        margin-right: 8px;
        img {
          width: 58px;
          border-radius: 2px;
          height: 28px;
          object-fit: contain;
        }
      }
    }
  }
}
