.PromoterList {
  .TopContainer {
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 12px;
    .WidgetCard {
      background: white;
      border-radius: 14px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      width: 19%;
      padding: 20px 24px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .InvestorDetail {
    .InvestorBasicInfo {
      display: flex;
      flex-wrap: wrap;
      margin-top: 28px;
      .InfoGroup {
        margin-right: 24px;
        margin-bottom: 24px;
      }
    }
    .TableContainer {
      .TableTopAction {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
