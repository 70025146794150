.PropertyEdit {
  height: 100%;
  display: flex;
  flex-direction: column;

  .form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .input-element {
      // margin: 1.4rem 0;
      width: 30%;

      input {
        // height: 2.8rem;
      }
    }
    .ant-upload {
      width: 100% !important;
    }
    .ant-upload-select {
      // width: 102px !important;
      border: none !important;
      background: none !important;
      height: auto !important;
    }
    .PlanDetailList {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .FormAction {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 768px) {
    //tablet screen
    .form-row {
      flex-direction: column !important;
      .input-element {
        width: 100% !important;
      }
    }
    .FormAction {
      button {
        width: 100% !important;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    //mobile screen
    .form-row {
      flex-direction: column !important;
      .input-element {
        width: 100% !important;
      }
    }
    .PropertyPhotoUploader {
      margin-bottom: 32px;
    }
    .FormAction {
      button {
        width: 100% !important;
      }
    }
  }
  .ant-input[disabled] {
    background-color: rgba(0, 0, 0, 0.04) !important;
    border: 1px solid #d9d9d9 !important;
  }
}
