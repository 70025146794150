.PhotoGallery {
  max-height: 500px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .Grid {
    width: 100px;
    margin-right: 12px;
    margin-bottom: 12px;
  }
}
