.PropertyList {
  height: 100%;
  display: flex;
  flex-direction: column;
  .TopSelectors {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftSelectors {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .rightSelectors {
      display: flex;
    }
    .WidgetContainer {
      min-width: 360px;
      margin-right: 24px;
    }
  }
  .ViewSwith {
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 14px;
  }
  .ListContainer {
    margin-top: 26px;
    display: flex;
    flex-wrap: wrap;
    // flex: 1;
    .CardContainer {
      margin-bottom: 24px;
      margin-right: 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    //tablet screen

    .TopSelectors {
      flex-direction: column;
      .leftSelectors {
        order: 2;
        flex-direction: column;
        margin-top: 12px;
      }
      .rightSelectors {
        margin-top: 24px !important;

        width: 100% !important;
        order: 1;
        justify-content: space-between;
        .WidgetContainer {
          width: 45% !important;
        }
      }
    }
    .ListContainer {
      margin-top: 24px;

      .CardContainer {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    //mobile screen

    .TopSelectors {
      flex-direction: column !important;
      .leftSelectors {
        flex-direction: column;
        .WidgetContainer {
          margin-right: 0;
          margin-bottom: 14px;
        }
      }
      .WidgetContainer {
        width: 100%;
        margin-right: 0;
      }
    }
    .ListContainer {
      margin-top: 44px;
      .CardContainer {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
