.LastPayment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .TopHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .titleContainer {
      display: flex;
      align-items: center;
    }
    .Title {
      margin-left: 12px;
    }
  }
  .Stats {
    display: flex;
    align-items: center;
    // margin-top: 24px;
    .TranscationCard {
      width: 164px;
      height: 88px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      margin-right: 22px;
      .TopContent {
        flex: 1;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding: 8px;
        padding-left: 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        .IconCircle {
          border-radius: 50%;
          background: white;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          margin-right: 12px;
        }
      }
      .BottomContent {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        height: 34px;
        padding-left: 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
      }
    }
  }
}
