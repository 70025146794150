.PropertyFilter {
  display: flex;
  width: 100%;
  .filterWidget {
    min-width: 150px;
    margin-right: 18px;
    .ant-select-selector {
      border-radius: 12px;
    }
    .filterButton {
      border: 1px solid #d9d9d9;
      background: white;
      height: 40px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      cursor: pointer;
      .buttonTitle {
        display: flex;
        align-items: center;
        .filterCount {
          background-color: black;
          border-radius: 50%;
          height: 16px;
          width: 16px;
          margin-right: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      p {
        // color: #d5d1d1;
      }
    }
  }
}

.MoreFilterAddon {
  .FilterContainer {
    max-height: 500px;
    overflow-y: auto;
    .FilterCategory {
      margin-bottom: 12px;
      .Content {
        display: flex;
        flex-wrap: wrap;
        .Chip {
          display: flex;
          align-items: center;
          border: 1px solid gray;
          border-radius: 20px;
          padding: 1px 8px;
          margin-right: 8px;
          margin-bottom: 8px;
          min-width: 28px;
          justify-content: center;
          cursor: pointer;
          &:hover {
            background-color: var(--base-color-red);
            transition: all 0.4s;
            border: 1px solid var(--base-color-red);
            p {
              color: white;
            }
            svg {
              color: white;
            }
          }
          &.--selected {
            background-color: var(--base-color-red);
            transition: all 0.4s;
            border: 1px solid var(--base-color-red);
            p {
              color: white;
            }
            svg {
              color: white;
            }
          }
        }
      }
    }
  }
}
