.QuickInsight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  .Stats {
    display: flex;
    flex-direction: column;
    .DetailView {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
