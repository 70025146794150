.ReferralDetail {
  width: 100%;
  // height: 200px;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  .TopHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .DetailInfo {
    margin-top: 24px;
    display: flex;
    align-items: center;
    .ReferalInfo {
      flex: 1;
      margin-left: 14px;
      .nameInfo {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
      }
    }
  }
}
