.LocationPicker {
  background-color: white;
  border-radius: 8px;
  height: 50px;

  .PickerButton {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
