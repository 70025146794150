.ant-upload {
  // width: auto !important;
}
.ant-upload-select {
  // width: 102px !important;
  //   border: 1px dashed #d9d9d9 !important;
  //   background: none !important;
  //   height: auto !important;
}
.FormAction {
  display: flex;
  justify-content: flex-end;
}

.ant-upload-picture-card-wrapper {
  display: flex !important;
  flex-direction: column !important;
  .ant-upload-drag {
    order: 2 !important;
  }
  .ant-upload-list-picture-card {
    order: 1 !important;
  }
}
