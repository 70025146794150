.Header {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  .headerRight {
    display: flex;
    align-items: center;
    .rightItem {
      margin-right: 32px;
    }
  }
}
@media only screen and (max-width: 768px) {
  //tablet screen
  .ant-layout-header {
    height: auto !important;
  }
  .Header {
    flex-direction: column !important;
    .leftSelectors {
      flex-direction: column;
    }
    .headerRight {
      width: 100% !important;
      .rightItem:first-child {
        display: flex !important;
        width: 100%;
        margin-right: 0 !important;
        .SearchBox {
          width: 100% !important;
        }
      }
      .rightItem {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  //mobile screen
  .ant-layout-header {
    height: auto !important;
  }
  .Header {
    flex-direction: column !important;
    .leftSelectors {
      flex-direction: column;
    }
    .headerRight {
      display: none;
    }
  }
}
