.AddAmentity {
    margin-top: 32px;
    overflow: hidden;
    .formContainer {
      max-height: 440px;
      overflow-y: auto;
      box-sizing: content-box;
      padding-right: 12px;
      margin-bottom: 24px;
      .form-row {
        display: flex;
        align-items: center;
        background-color: white;
        border-radius: 8px;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        margin-bottom: 24px;
        padding: 2px;
        .input-element {
          width: 43%;
          input {
            height: 2.2rem;
          }
        }
        span {
          cursor: pointer;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  
    .action-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 4rem;
    }
  }
  