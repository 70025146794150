.HouseInfoCard {
  border-radius: 16px;
  background-color: white;
  height: 120px;
  padding: 16px 20px;
  box-sizing: border-box;
  min-width: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  .CardTitle {
    display: flex;
    align-items: center;
  }

  .InfoContainer {
    margin-right: 28px;
  }
}
