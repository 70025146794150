//Ant Tab modifiers
.ant-tabs-ink-bar {
  padding-left: 66px;
  padding-right: 66px;
}
.ant-tabs-nav::before {
  border-bottom: 0 !important;
}

.ant-tabs-content-holder {
  margin-top: 22px;
}
.ant-tabs-tab-btn {
  font-size: 16px;
}
