.SaleList {
  width: 100%;
  background-color: white;
  border-radius: 16px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  .EarningListContent {
    padding: 0 24px;
    .EarningTypes {
      display: flex;
      align-items: center;

      .EarningTypeCard {
        display: flex;
        align-items: center;
        background-color: var(--base-color-gray-light);
        padding: 12px;
        box-sizing: border-box;
        border-radius: 16px;
        min-width: 180px;
        height: 80px;
        margin-right: 24px;
        .IconBox {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .EarningDetail {
          margin-left: 12px;
        }
      }
    }
    .TableContainer {
      max-height: 300px;
    }
  }

  .ant-tabs-nav-wrap {
    justify-content: flex-start !important;
    border-bottom: 1px solid #8792a4;
    .ant-tabs-tab {
      // min-width: 50px !important;
      // justify-content: center !important;
      margin-left: 100px !important;
      &:first-child {
        margin-left: 50px !important;
      }
    }
  }

  .ant-table-thead > tr > th {
    background-color: #7c8091cc !important;
    color: white;
  }
  th.ant-table-column-sort {
    background-color: #7c8091cc;
  }
}
