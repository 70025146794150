.BrokerDetails {
  .DetailRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    .Info {
      width: 50%;
    }
    .ReferDetail {
      margin-top: 24px;
      background-color: #f4f6f9;
      border-radius: 12px;
      padding: 12px;
      box-sizing: border-box;
      width: 100%;
      .UserInfo {
        margin-top: 12px;
        display: flex;
        align-items: center;
        .ReferalInfo {
          flex: 1;
          margin-left: 10px;
          .nameInfo {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
