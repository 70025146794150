.BrokerDashCard {
  width: 100%;
  height: 200px;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .TopHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .HeaderLeft {
      display: flex;
      align-items: center;
      .HeaderInfo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 16px;
        .TopStateNumber {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .Stats {
    background-color: rgba(244, 246, 249, 1);
    border-radius: 12px;
    height: 68px;
    padding: 6px 0;
    display: flex;
    align-items: center;
    .Stat {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 22px;
      .StateDetail {
        margin-left: 6px;
      }
    }
    .Stat:first-child {
      border-right: 1px solid rgba(202, 209, 216, 1);
    }
  }
}
