.TopPerformers {
  width: 100%;
  // height: 200px;
  background-color: white;
  border-radius: 16px;
  // padding: 16px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  .TopHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .UserList {
    margin-top: 18px;
    .UserInfo {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      justify-content: space-between;
      .UserInfoAttribute {
        display: flex;
        align-items: center;
      }
    }
  }
}
