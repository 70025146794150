.RolesAccess {
  display: flex;
  .RolesMenus {
    width: 200px;
    display: flex;
    flex-direction: column;
    border: 1px solid #f0f0f0;
    .MenuItem {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 9px 20px;

      &:hover {
        background-color: rgb(236, 234, 234);
        transition: all 0.4s;
      }
    }
    .--selected {
      background-color: rgb(236, 234, 234);
    }
  }
  .TableContainer {
    flex: 1;
  }
}
