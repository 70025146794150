.OffersList {
  
  .TopSelectors {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftSelectors {
      display: flex;
      align-items: center;
    }
    .rightSelectors {
      display: flex;
    }
    .WidgetContainer {
      min-width: 360px;
      margin-right: 24px;
    }
  }

  #tabBarContainer{
    margin-top: 32px;
  }
}
