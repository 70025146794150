.BrokerDashboardVisit {
  .TopHeader {
    display: flex;
    align-items: center;
    .WidgetTop {
      display: flex;
      align-items: center;
      margin-right: 28px;
      .StatusChip {
        margin-left: 8px;
        height: 28px;
        border-radius: 8px;
        padding: 0 8px;
        background-color: rgba(115, 175, 0, 0.13);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .Row {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin-top: 24px;
    .WidgetContainer {
      margin-right: 24px;
    }
  }
}
